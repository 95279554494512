import React, { useRef } from "react";
import { useShouldMirrorVideo } from "../../hooks/useShouldMirorVideo";
import { setSystemStore, ESystemActions } from "../../../reducers/system";
import { useCamera } from "../../hooks/useCamera";
import { logInfo, logDebug } from "../../helpers/logger";
import { useAppSelector, useAppDispatch } from "../../../store/index";
import { IconLoading } from "../icons/IconLoading";
import { CameraSwitch } from "./CameraSwitch";
import { ErrorType } from "../../../types";
import ButtonBack from "./ButtonBack";
import DebugImage from "./DebugImage";
import { calculateMaskSize } from "../../helpers/utils";

interface IProps {
    goBack: () => void;
    videoRef: React.RefObject<HTMLVideoElement>,
    label: string,
    isFace: boolean,
    debug: boolean,
    iconType: EIconType,
    frameClass: string,
    loading?: boolean,
    message: string,
    sendErrorMessage: (error: ErrorType) => void,
    aspectWidth?: number,
    aspectHeight?: number
}

export enum EIconType {
    warning,
    error,
    success
}

export const LiveVideoElement: React.FC<IProps> = ({
    goBack,
    videoRef,
    label,
    isFace,
    debug,
    iconType,
    frameClass,
    loading,
    message,
    sendErrorMessage,
    aspectWidth,
    aspectHeight
}) => {
    const dispatch = useAppDispatch()

    const mirror = useShouldMirrorVideo()
    const ref = useRef<HTMLDivElement>(null)

    const videoOptions = useAppSelector(store => store.systemStore.videoOptions)

    let cameraInitialising = useCamera(videoRef, videoOptions, sendErrorMessage)

    const clientWidth = ref.current ? ref.current.clientWidth : 0
    const clientHeight = ref.current ? ref.current.clientHeight : 0

    const { mask, styles, overlayMask } = calculateMaskSize(
        clientWidth,
        clientHeight,
        isFace ? 1 : aspectWidth,
        isFace ? 1 : aspectHeight,
        isFace ? true : false
    )

    let icon = "s_at"
    let iconClass = "type2"

    if (iconType === EIconType.error) {
        icon = "s_cross"
        iconClass = "type3"
    }

    if (iconType === EIconType.success) {
        icon = "s_check"
        iconClass = ""
    }

    return (
        <>
            <IconLoading show={cameraInitialising || (loading ? loading : false)} />
            <div className={`box_video ${frameClass}`}>
                <div className="box_video_inner">
                    <div className="box_video_top">
                        <div className="box_video_semi box_video_text">{label}</div>
                    </div>
                    <div className="box_video_content">
                        <DebugImage show={debug} />
                        <video
                            id={"video_element"}
                            className={mirror ? "mirror" : ""}
                            ref={videoRef}
                            autoPlay
                            muted
                            playsInline
                            onPlaying={() => {
                                logDebug("video stream stated");
                                logInfo(
                                "Video stream resolution: (width x height) " +
                                    videoRef.current?.videoWidth +
                                    "x" +
                                    videoRef.current?.videoHeight
                                );
                                dispatch(
                                setSystemStore(ESystemActions.SET_VIDEO_IS_PLAYING, true)
                                );
                            }}
                        />
                        {mask}
                        <div className="box_video_cover" ref={ref} />

                        {styles}

                        <div className="box_video_frame">{overlayMask}</div>

                        <CameraSwitch />
                    </div>

                    <div className="box_video_bottom">
                        <div className="box_video_semi box_video_text w100p">
                            <span className={`icon ${iconClass}`}>
                                <svg
                                    viewBox="0 0 21 21"
                                    className="sicofill"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <use href={`/img/layout/icons.svg#${icon}`} />
                                </svg>
                            </span>
                            <span>{message}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main_bottom">
                <div className="box_buttons">
                    <div className="box_buttons_left">
                        <ButtonBack onClick={goBack} />
                    </div>
                </div>
            </div>
        </>
    );
};
