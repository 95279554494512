import React, { useRef, useState, useEffect } from "react";

import { IconLoading } from "../icons/IconLoading";
import { calculateMaskSize } from "../../helpers/utils";
import ButtonBack from "./ButtonBack";
import { Trans, t } from "@lingui/macro";

interface IProps {
  image: string;
  onAccept: () => void;
  onCancel: () => void;
  loading: boolean;
  imgAlt: string;
  badCard: boolean;
  badSide: boolean;
  aspectWidth?: number;
  aspectHeight?: number;
}

export const ConfirmPhoto: React.FC<IProps> = ({
  image,
  onAccept,
  onCancel,
  loading,
  badCard,
  badSide,
  imgAlt,
  aspectWidth,
  aspectHeight,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState(0);
  const [customLoading, setCustomLoading] = useState(false);

  const clientWidth = ref.current ? ref.current.clientWidth : 0;
  const clientHeight = ref.current ? ref.current.clientHeight : 0;

  const { styles } = calculateMaskSize(
    clientWidth,
    clientHeight,
    aspectWidth,
    aspectHeight,
    true
  );

  useEffect(() => {
    setTimeout(() => {
      setKey(1);
    }, 50);
  }, []);

  useEffect(() => {
    if (badCard || badSide) {
      setCustomLoading(false);
    }
  }, [badCard, badSide]);

  return (
    <>
      <IconLoading show={loading || customLoading} />
      <div className={`box_video ok`} key={key}>
        <div className="box_video_inner">
          <div className="box_video_content">
            <video
              id={"video_element"}
              title={imgAlt}
              autoPlay
              muted
              playsInline
              poster={image}
            />

            {/* <div className="box_video_cover" ref={ref}></div> */}

            {styles}

            <style>
              {`
                                .box_video{
                                    --h-per: 56%;
                                    --w-per: 56%;
                                    --w-m-per: 88%;
                                    --h-m-per: 44%;
                                }
                            `}
            </style>

            <div className="box_video_frame"></div>

            {badCard || badSide ? (
              <div className="box_video_bottom">
                <div className="box_video_semi box_video_text w100p">
                  <span className="icon type2">
                    <svg
                      viewBox="0 0 21 21"
                      className="sicofill"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="/img/layout/icons.svg#s_at"></use>
                    </svg>
                  </span>
                  <span>
                    {badCard ? (
                      <Trans>
                        Zosnímaná karta nie je správna. Skúste znova.
                      </Trans>
                    ) : null}
                    {badSide ? (
                      <Trans>
                        Nasnímali ste nesprávnu stranu karty. Otočte kartu a
                        skúste znova.
                      </Trans>
                    ) : null}
                  </span>
                </div>
              </div>
            ) : (
              <div className="box_video_top">
                <div className="box_video_semi box_video_text">
                  <Trans>Fotka úspešne zosnímaná</Trans>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {badCard || badSide ? null : (
        <div className="box_msg_cont">
          <div className="box_msg">
            <div className="icon">
              <svg
                viewBox="0 0 21 21"
                className="sicofill"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use href="/img/layout/icons.svg#s_at" />
              </svg>
            </div>
            <div className="box_msg_text">
              <Trans>
                Skontrolujte či je fotografia dostatočne ostrá a preukaz je celý
                viditeľný.
              </Trans>
            </div>
          </div>
        </div>
      )}

      <div className="main_bottom">
        <div className="box_buttons">
          <div className="box_buttons_left">
            <ButtonBack
              onClick={() => onCancel()}
              customTitle={t`Zosnímať znova`}
            />
          </div>

          {badCard || badSide ? null : (
            <div className="box_buttons_right">
              <button
                className="btn_basic bordered"
                onClick={() => {
                  setCustomLoading(true);
                  onAccept();
                }}
              >
                <span className="text">
                  <Trans>Pokračovať</Trans>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
